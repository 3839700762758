import React from "react";
import aboutImage2 from "../../images/1981-JCI_President.jpg";
import sectionIcon from "../../images/section-icon.png";
const WhyJci = () => {
  return (
    <section className="about-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="about-semi-img">
              <img src={aboutImage2} alt="" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-heading">
              <div className="section-heading">
                <div className="section-icon">
                  <img src={sectionIcon} alt="section-icon" />
                </div>
                <h2 className="section__title">Junior Chamber International</h2>
                <p className="section__meta">why JCI</p>
                <p className="section__desc">
                  Junior Chamber International (JCI) is a worldwide federation of young leaders and entrepreneurs with nearly five lakh active members and millions of alumni spread across more than 115 countries.
                </p>
                <p className="section__desc">
                  JCI orgin can be traced back to 1915 to the city of St. Louis, Missouri, USA where Sir Henry Giessenbier with 32 other young men established Young Men's Progressive Civic Association (YMPCA).
                </p>
                <p className="section__desc">
                  In 1916, the name was changed from YMPCA to "Junior Citizens", commonly called "JCs", which later became "Jaycees"
                </p>
                <p className="section__desc">
                  Their work so impressed the St. Louis Junior chamber of Commerce that they asked these young men in 1918 to adopt the name “Junior Chamber of Commerce” which later became “Junior Chamber”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyJci;
